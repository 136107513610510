#register{
    width: 100%;
    color: #252831;
    .country-list {
        color: #252831;
    }
    h2{
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 2rem;
        text-transform: uppercase;
        color: #252831;

        @include mobile{
            margin-top:0;
        }
    }

    .age{
        @extend .smaller-font;
    }

    .form-group{
        margin: 0;
        position: relative;
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
    }

    .form-group-container{
        display: flex;

        .form-group{
            &:first-child:not(:last-child){
                margin-right: 1rem;
            }
        }
    }

    .show-password{
        outline: none !important;
        cursor: pointer;
        background: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        width: 3rem;

        &.visible{
            img{
                opacity: 0.7;
            }
        }
    }

    .flag-container{
        outline: none !important;
        height: 100%;
        max-height: 3rem;
        height: 2.6rem;
        top: 0.3rem;
    }

    .selected-flag{
        outline: none !important;
    }

    .password-input-container{
        margin-bottom: 0.5rem;
    }

    input:not([type="checkbox"]){
        width: 100%;
    }


    input{
        height: 2.6rem;
        @extend .smaller-font;
        display: flex;
        align-items: center;
        transition: border 300ms ease;
        border: 1px solid #dadada;
        border-radius: 10px;
        color: #0d0c0a;
        padding: 0 1.3rem;
        margin-bottom: 0.4rem;
        border: 0;
        transition: all 0.2s ease-in;
        background-color: #fff;
        opacity: 1;

        &:focus{
            background-color: rgba($color: #fff, $alpha: 0.7);
        }

        &::placeholder{
            opacity: 1;
            color: #0d0c0a;
        }
    }

    input.phone{
        margin-bottom: 0.5rem !important;
        margin-top: 0.3rem !important;
    }

    .rules{
        li{
            @extend .smaller-font;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 0.5rem;
            position: relative;
            transition: all .15s ease-in-out;

            i{
                margin-right: 7px;
                font-size: 0.7rem;
            }

            &.valid{
                color: $register-error-valid-color;
            }

            &.invalid{
                color: $register-error-invalid-color;
            }
        }
    }

    label.error{
        @extend .smaller-font;
        display: flex;
        align-items: center;
        color: $register-error-color;
        width: 100%;
        margin-bottom: 0.5rem;
        position: relative;
        transition: all .15s ease-in-out;

        img{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 1rem;
            visibility: hidden;
            opacity: 0;
            transition: all .15s ease-in-out;
        }
    }

    label.error[for=password]{
        color: white;

        &.error-correct{
            color: $primary;
            img{
                visibility: visible;
                opacity: 1;
            }
        }
    }

    label.error[for=accept_terms] {
        text-align: center;
        position: absolute;
        display: flex;
        bottom: 0.1rem;
        margin: 0 !important;
    }

    .currency-container{
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .currency-label,
        .selected-currency,
        .currency-list label{
            color: $register-text-color;
            font-family: $font-primary;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        .currency-label{
            margin-right: 12px;
        }

        .currency-select{

            position: relative;

            .selected-currency{
                position: relative;
                z-index: 4;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 7px 12px;
                cursor: pointer;
                transition: background-color .15s ease-in-out;

                span{
                    width: 2.5rem;
                    display: flex;
                    align-items: center;
                }

                img{
                    margin-right: 15px;
                }

                svg{
                    height: 8px;
                    width: auto;
                    fill: $register-arrow-color;
                }

                &:hover{
                    background-color: rgba(255,255,255,0.1);
                }
            }
    
            .currency-list{

                position: absolute;
                z-index: 3;
                top: 100%;
                left: 0;
                background-color: #fff;
                box-sizing: border-box;
                overflow: hidden;
                flex-direction: column;
                transform: translateY(-50%);
                left: 100%;
                opacity: 0;
                visibility: hidden;
                border-radius: 5px;
                transition:all 0.3s ease;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
                width:6.5rem;

                &.visible{
                    opacity: 1;
                    visibility:visible;
                }

                li{
                    box-sizing: border-box;

                    label{
                        width: 100%;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        user-select: none;
                        position: relative;
                        box-sizing: border-box;
                        padding: 7px 12px;
                        color: $register-currency-color;

                        input{
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                        }

                        img{
                            margin-left: 15px;
                        }

                    }

                    &:hover{
                        background-color: #f7f7f7;
                    }
                }
            }

            &:hover{
                .currency-list{
                    display: flex;
                }
            }
        }

        img{
            height: 15px;
            width: auto;
        }
    }

    .form-check-label{
        display: flex;
        margin-bottom: 30px;
        color: rgba($color: $register-text-color, $alpha: 1);
        cursor: pointer;

        a{
            color: $register-text-color;
            font-weight: 700;
        }

        input[type=checkbox]{
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
        }

        .custom-checkbox{
            position: relative;
            display: flex;
            min-width: 15px;
            height: 15px;
            margin-right: 15px;
            border-radius: 3px;
            background-color: #ffffff;
            box-sizing: content-box;
            border: solid 1px rgba(0,0,0,0.1);
            cursor: pointer;
            margin-top: 0.2rem;

            @include bigtablet-down{
                margin-top: 0;
            }

            &:after{
                content: "";
                position: absolute;
                width: 11px;
                height: 11px;
                left: 2px;
                top: 2px;
                border-radius: 3px;
                background-color: $primary;
                opacity: 0;
                transition: all .1s ease-in-out;
            }
        }

        input[type=checkbox]:checked + .custom-checkbox:after{
            opacity: 1;
        }
    }

    .disclaimer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

}

.register-container{
    box-sizing: border-box;
    width:30rem;
    width:480px;
    background-color: #feb903;
    padding:3rem 2rem 5.5rem;
    margin-bottom: -2rem;
    border-radius: 0 0 10px 10px;


    @include smalltablet-down{
        width: 360px;
    }

    @include mobile{
        width: 100%;
        border-radius: 10px;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.register-container form{
    padding-bottom: 0 !important;
}
.register-container{
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out 0.5s;
}
.register-container.focused{
    box-shadow:  0 0 15px 2px gold;
}


@media (max-width: 767px){
    // Mobile Form Transition

//     .register-container{
//         position: fixed;
//         top:0;
//         left:100% !important;
//         width: 100% !important;
//         height: 100% !important;
//         z-index: 99;
//         transition:left 0.4s cubic-bezier(0,0,.22,.99);
//         padding:25px;
//         -webkit-overflow-scrolling: touch;
//         overflow: auto;
//         overflow-x: hidden;
//         border-radius:0;
//     }

//    .mobile-open{
//        overflow: hidden;

//            .register-container{
//                left:0 !important;
//            }
//    }

//    header,
//    main,
//    footer{
//        left:0;
//        transition:left 0.4s cubic-bezier(0,0,.22,.99) !important;
//    }
//    main,
//    footer{
//        position: relative;
//    }
//    .mobile-open{
//        .header,
//        main,
//        footer{
//            left:-100%;
//        }
//    }
}



@include big-desktop-up{
    .register-container{
        width: 500px;
    }
}
@include mobile-portrait{
    .register-container{
        padding: 4rem 1rem;
    }
}


.register-container{
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.5);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        opacity: .5;
        filter: blur(30px);
        transform: translateX(-100px)  skewX(-15deg);
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.2);
        width: 30px;
        height: 100%;
        left: 30px;
        top: 0;
        opacity: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
      }
      &.focused{
       
        cursor: pointer;
        &:before{
          transform: translateX(350px)  skewX(-15deg);  
          opacity: 0.6;
          transition: .7s;
          transition-delay: .6s;

          @include smalltablet-down{
            transform: translateX(200px)  skewX(-15deg);  
          }
          @include mobile{
            transform: translateX(400px)  skewX(-15deg);  
          }
          @include mobile-portrait{
            transform: translateX(250px)  skewX(-15deg);  
          }
        }
        &:after{
          transform: translateX(350px) skewX(-15deg);  
          opacity: 1;
          transition: .7s;
          transition-delay: .6s;

          @include smalltablet-down{
            transform: translateX(200px)  skewX(-15deg);  
          }
          @include mobile{
            transform: translateX(400px)  skewX(-15deg);  
          }
          @include mobile-portrait{
            transform: translateX(250px)  skewX(-15deg);  
          }
        }
      }
}


@media (max-width: 359px){
    #register .form-group-container .form-group:first-child:not(:last-child){
        margin-right:0;
    }
    #register .form-group-container{
        flex-direction: column;
    }
}