#section2{
    .container{
        display: flex;

        @include mobile{
            display: block;
        }

        .selector-list-wrapper{
            display: flex;

            .selector-card{
                position: relative;
                width:25%;
                padding:4rem 2rem;
                border-radius: 0 0 10px 10px;
                background-color: transparent;
                transition: background-color 0.4s ease-in-out;
                cursor: pointer;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                height: 100%;

                @include smalltablet-down{
                    padding:2.5rem 1rem;
                }

                @include mobile{
                    padding:2.5rem 2rem;
                    border-radius: 0;
                    border-bottom: 1px solid #dddee2;

                

                    &:last-child{
                        border: 0;
                    }
                    img{
                        max-width: 25px;
                        height: auto;
                        margin-right:1rem;
                    }
                }
                 
                &:before{
                    content: "";
                    height: 100%;
                    left: 0;
                    top:0;
                    width:1px;
                    background-color: #e0e3eb;
                    position: absolute;
                    transition: opacity 0.4s ease-in-out;
                    opacity: 1;
                }

                &:last-child:after{
                    content: "";
                    height: 100%;
                    right: 0;
                    top:0;
                    width:1px;
                    background-color: #e0e3eb;
                    position: absolute;
                    transition: opacity 0.4s ease-in-out;
                    opacity: 1;
                }

                .selector-icon{
                    height: 47px;
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 3rem;

                    @include mobile{
                        height: auto;
                        align-items: center;
                        margin:0;
                    }
                }

                &:hover{
                    background-color: $secondary;

                    &:before, &:after{
                        opacity: 0;
                    }

                    p{
                        color: #2d272f;
                    }

                    &+.selector-card{
                        &:before{
                            opacity: 0;
                        }
                    }
                }

                h5{
                    margin-bottom: 3rem;
                    text-transform: uppercase;
                }

                p{
                    margin-bottom: 3rem;
                    color: #83868e;
                    transition: color 0.4s ease-in-out;
                }
                .read-more{
                    display: flex;
                    align-items: center;

                    img{
                        margin-right:1rem;

                        @include mobile{
                            max-width: 8px;
                            display: block;
                        }
                    }
                }
            }
            @include mobile{
                display: block;

                .selector-card{
                    width: 100%;
                    flex-flow: row;
                    height:auto;
                    align-items: center;
                    justify-content: flex-start;

                    &:before, &:after{
                        display: none;
                    }

                    .selector-top{
                        display: flex;
                    }

                    h5{
                        margin: 0 0 0.5rem;
                        br{
                            display: none;
                        }
                    }

                    .selector-title{
                        margin-left:2rem;

                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                    }


                }
            }
        }
    }

    
}

@media (min-width:768px) and (max-width: 991px){
    #section1 h2{
        font-size: 1.8rem;
    }
}