#section1{
    background-color: #252831;
    color: #ffffff;
    text-align: center;
    padding:7rem 0 0;
    font-family: $font-secondary;

    p{
        text-transform:uppercase;

       
    }
    h2{
        padding-bottom:1rem;

        @include mobile{
            font-size: 1.8rem;
        }
    }

    @include mobile{
        padding-top:0;
    }
}

.arrow-wrapper{
    padding:3.2rem 0;
    text-align: center;

    @include mobile{
        padding:2.5rem 0;
    }

    img{
        display: inline-block;
        vertical-align: middle;
    }
}