#footer{
    
    .payment-providers{
        background-color: $payment-providers-background;

        ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border-top: 1px solid #dfdfe7;

            li{
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding: 2.5rem 0;
                transition: all 200ms ease-in-out;

                img{
                    max-height: 2rem;
                }
            }

            
        }
    }


    .warnings{
        background-color: $footer-disclaimer-background;
        
        .warnings-inner{
            border-top: 1px solid #dfdfe7;
            padding-bottom: 2rem;
            padding-top:2rem;
        }

        p{
            @extend .smaller-font;
            line-height: 1.15rem;
            color: $footer-disclaimer-color;
            text-align: justify;

            @include mobile{
                font-size: 1rem;
            }
        }

        a{
            color: $footer-disclaimer-link-color;

            &:hover{
                color: $footer-disclaimer-link-hover-color;
            }
        }

    }

    .copyright{
        background-color: $footer-copyright-background;

        .footer-logo{
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-right:2rem;
    
            img{
                width:5.6rem;

                @include mobile{
                    width: 7rem;
                }
            }
        }

        .copyright-inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding-top: 2.3rem;
            padding-bottom: 2.3rem;
            border-top: 1px solid #dfdfe7;

            @include mobile{
                display: block;
            }

        }

        .copyright-left{
            display: flex;
            align-items: center;

            @include mobile{
                padding-bottom: 2rem;
            }

            small{
                @extend .smaller-font;
            }
        }

        .social-media-container{
            margin-left: -1rem;

            .social-media-link{
                display: inline-block;
                padding: 1rem;
                opacity: 1;
                transition: all .15s ease-in-out;

                img{
                    height: 1rem;
                }

                &:hover{
                    opacity: 0.7;
                }
            }
        }

        .badges-container{
            .badge{
                display: inline-block;

                &:not(:last-child){
                    margin-right: 10px;
                }
    
                img{
                    display: table;
                    height: 2.25rem;

                    
                }
            }
        }
        
    }
}


@media (max-width: 767px){
    #footer{
        .payment-providers{
            padding: 2rem 0 0;

            @include mobile{
                padding-top:0;
            }

            ul{
                padding-bottom: 2rem;
                li{
                    width:33.3%;
                    justify-content: center;
                    padding: 2rem 0 0;

                    img{
                        max-height: 2rem;
                        max-width: none;
                        object-fit: fill;
                        max-width: 100%;
                    }

                    &:nth-child(3n+1){
                        justify-content: flex-start;
                    }
                    &:nth-child(3n+3){
                        justify-content: flex-end;
                    }
                    &:nth-child(5){
                        padding-left:1rem;
                    }
                }
            }
        }
    }
}