.button,
.button:hover,
.button:active,
.button:focus{
    outline: none !important;
    background-color: transparent;
    border: none;
    font-family: $font-primary;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    padding: 1rem 2rem;
    top: 0;

    @include mobile{
      padding:1.5rem 2.5rem;
    }
}

.button-full,
.button-full:hover,
.button-full:focus,
.button-full:active
{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: white;
    background-color: $primary;
    border-radius: 10px;
    width: 16.5rem;
    max-width: 100%;
    font-weight: 400;
    line-height: 1;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0px 3px 0px 0px rgba(16,130,154,1);

    @include mobile{
      font-size: 1.2rem;
      width:auto;
    }


}



.button:hover{
    background-color: $primary;
    color:white;
    box-shadow: 0px 5px 0px 0px rgba(16,130,154,1);
    top: -2px;
}

.button:active{
  box-shadow: 0px 1px 0px 0px rgba(16,130,154,1);
  top:2px;
}



#juventus-section .button:hover{
  background-color: $primary;
  color:white;
  box-shadow: 0px 5px 0px 0px #c0392b;
  top: -2px;
}

#juventus-section  .button:active{
box-shadow: 0px 1px 0px 0px #c0392b;
top:2px;
}



