#section3{
    .arrow-wrapper{
        padding: 4rem 0;

        @include mobile{
            display: none;
            padding-bottom: 0;
        }
    }
    .features-card{
        &.blue{
            .features-text{
                background-color: #48abff;
                color: white;
            }
        }

        &.yellow{
            .features-text{
                background-color: #feb903;
                color: #252831;

                ul{
                    li{
                        &:before{
                            background-image: url(../../images/Checkmark_black.png);
                        }
                    }
                }
            }
            .features-image{
                img{
                    left:auto;
                    right:0;
                }
            }
        }

        &.one{
            h5:before{
                background-image: url(../../images/title-bg-1.png);

                
            }
        }
        &.two{
            h5:before{
                background-image: url(../../images/title-bg-2.png);
                left:-0.7rem;
                top:-0.3rem;
            }
        }
        &.three{
            h5:before{
                background-image: url(../../images/title-bg-3.png);
                left:-0.7rem;

                @media (max-width: 1999px){
                    top:-0.3rem;
                }
                @include smalltablet-down{
                    top:-0.1rem;
                }
            }
        }
        &.four{
            h5:before{
                background-image: url(../../images/title-bg-4.png);
                left:-1rem;
                @include smalltablet-down{
                    top:0.4rem;
                }
            }
        }
    }
    .features-card-inner{
        display: flex;
        border-radius: 10px;
        overflow: hidden;

        @include mobile{
            display: block;
            margin-bottom: 3rem;

        }

        .features-text,
        .features-image{
            width:50%;
            position: relative;

            @include mobile{
                width: 100%;
            }
            img{
                position: absolute;
                top:0;
                left:0;
                height: 100%;
                
                max-width: unset;
                min-width: 100%;

                @include mobile{
                    position: static;
                    min-width: unset;
                    max-width: 100%;
                    height:auto;
                }
            }
        }

        .features-text{
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding:4rem 3.5rem 2.5rem 1.8rem;
            position: relative;
            z-index: 1;
        }

        h5{
            text-transform: uppercase;
            margin-bottom:1rem;
            position: relative;
            margin-left: 1rem;
            padding: 1rem;
            margin-top:-1rem;

            @include mobile{
                margin-left: -1rem;
            }

            &:before{
                content:"";
                position: absolute;
                top:0;
                left:0;
                background-repeat: no-repeat;
                height:100%;
                width:100%;
                z-index: -1;

                @include smalltablet-down{
                    background-size: contain;
                }
    
            }
        }
    
        ul{
            padding-bottom:2rem;

            li{
                position: relative;
                padding-left:2rem;
                margin-bottom:1rem;

                &:before{
                    width:0.6rem;
                    height: 0.65rem;
                    position: absolute;
                    left:0;
                    top:0.3rem;
                    content:"";
                    background-image: url(../../images/Checkmark_white.png);
                    background-size: contain;
                    background-repeat: no-repeat;

                    

                
                    
                }
                @include mobile{
                    padding-left: 0;

                    &:before{
                        display: none;
                    }
                }
            }
        }
        .register-link{
            text-align: right;
            font-size: 1.1rem;

            a{
                cursor: pointer;
                transition:opacity 0.2s ease-in-out;
                opacity: 1;

                &:hover{
                    opacity: 0.8;
                }

                @include mobile{
                    display: inline-block;
                    padding-left:2rem;
                    position: relative;

                    &:before{
                        position: absolute;
                        content: '';
                        left:0;
                        top:52%;
                        transform: translateY(-50%);
                        height:9px;
                        width:7px;
                        background-image: url(../../images/mobile-arr-right.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    
}

@include mobile{
    #section3 .blue .features-card-inner .register-link a:before{
        background-image: url(../../images/mobirle-arr-right-white.png);
    }
}