html{
    font-size: 16px;
}

body,
html{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}

body{
    font-family: $font-primary;
    font-size: 1rem;
    color: #2d272f;
    line-height: 1.4;
    background: #fff;
}

::selection{
    background: $primary;
    color:white;
}
::-moz-selection { 
    background: $primary;
    color:white;
}

section{
    overflow: hidden;
    position: relative;
}

main{
    min-height: 100vh;
}

ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a,
a:hover,
a:active,
a:focus{
    text-decoration: none;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6{
    font-family: $font-secondary;
    margin: 0;
    line-height: 1.1;
}

h1{
    font-size: 2.8rem;
    font-weight:  800;
}
h2{
    font-size: 2.3rem;
}
h3{
    font-size: 1.8rem;
    font-weight: 300;

    strong{
        font-weight: 700;
        text-transform: uppercase;
    }
}
h4{
    font-size: 1.3rem;
}
h5{
    font-size: 1.1rem;
}

input{
    outline: none !important;
}

.subtitle{
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #4a4a4a;
    text-align: center;
    font-weight: 600;

    .big{
        font-size: 2.6rem;
        font-weight: 900;
    }
}

p{
    margin: 0;
}

.text-highlight{
    color: $primary;
}
.text-center{
    text-align:center;
}

.flex-grow-1{
    flex-grow: 1;
}

.disclaimer{
    margin-top: 1rem;
    display: inline-block;
    color: rgba($color: #000000, $alpha: 0.5);

    &.light{
        color: rgba($color: white, $alpha: 0.5);
    }
}

img{
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.close-icon{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 10px;
    box-sizing: content-box;

    div{
        position: relative;
        width: 100%;
        height: 100%;

        &:after,
        &:before{
            content: "";
            position: absolute;
            top: 48%;
            left: 14%;
            transform: translate(-50%,-50%);
            width: 22px;
            height: 3px;
            border-radius: 7px;
            background-color: #fff;
            transform-origin: 50% 50%;
        }

        &:after{
            transform: rotate(45deg);
        }

        &:before{
            transform: rotate(-45deg);
        }
    }

}

@media (max-width: 767px){

    .subtitle{
        font-size: 1.7rem;
        .big{
            font-size: 2.3rem;
        }
    }

    .no-mobile{
        display: none !important;
    }

}


@media (min-width: 768px){

    .mobile{
        display: none !important;
    }

}

@media (max-width: 991px){

    .no-tablet{
        display: none !important;
    }

}

@media (min-width: 992px){

    .tablet{
        display: none !important;
    }

}
// based on height
@media (max-height: 750px){
    html{
        font-size: 14px;
    }
}

@media (max-width: 1199px){
    html{
        font-size: 14px;
    }
}
@media (max-width: 1023px){

    
}
@media (max-width: 359px){
    html{
        font-size: 13px;
    }
}




@include big-desktop-up{
    html{
        font-size:18px;
    }
    .container{
        max-width: 1230px;
    }
}

