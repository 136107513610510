#hero{
    background-color: $primary;
    @extend .background-image;
    background-image: url(../../images/hero-bcg.png);
    color: #ffffff;
    overflow: visible;
    z-index: 2;

    @include mobile{
        overflow: hidden;
    }

    

    .register-container{
        margin-bottom: -2rem;
        overflow: hidden;

        @include mobile{
            width: auto;
           margin: 0;
           position: relative;
           top:-4rem;
        }
    }

    .container{
        display: flex;
        align-items: center;

        @include mobile{
            display: block;
        }

        .left-side{
            flex-grow: 1;
            padding-top: 4rem;
        }
       
    }

 

    @include mobile{
        background: $primary;
    }
    @include mobile-portrait{
       
    }

  

    h3{
        text-transform: uppercase;
        font-weight: 400;
        margin-top: 3rem;
        line-height: 1.2;

        @include mobile{
            font-size: 1.6rem;
            margin-top: 1rem;
        }
        @include mobile-portrait{
            @include fluid-type(16px, 28px, 320px, 565px);
            margin-top: 2rem;
        }

        @media (max-width: 413px){
            margin-top: 3rem;
        }
    }

    h1{
        text-transform: uppercase;
        font-weight: 800;


        @include mobile-portrait{
            @include fluid-type(26px, 40px, 320px, 565px);
        }
    }

    h2{
        margin: 2.5rem 0 0;
    }

    h4{
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        @include mobile-portrait{
            @include fluid-type(14px, 24px, 320px, 565px);
        }
    }
   

    @media (max-width: 991px) and (min-width: 768px){
        h1{
            font-size: 2.3rem;
        }
        h4{
            font-size: 1.4rem;
        }
        h3{
            font-size: 1.6rem;
            position: relative;
            top: 2rem;
        }
        .container{
            .left-side{
                padding-top: 7rem;
            }
        }
    }

    @include mobile{
        padding-top:7rem;

        .left-side{
            padding-bottom: 10rem;
        }
        .right-side{
            margin: 0 -9999px;
            padding:0 9999px;
            background-color: #252831;
            width: auto;
         }

        @include mobile{
            &:before{
                background-image: url(../../images/hero-bcg-mobile.png);
                background-position: center bottom;
                background-size: cover;
                content:"";
                position: absolute;
                left:0;
                top:0;
                height:75%;
                width: 100%;
            }

            .container{
                position: relative;
            }
        
        }
    
    @include mobile-portrait{
        &:before{
            height:55rem;
        }
        .container{
            position: relative;
            .left-side{
                padding-bottom: 8rem;
                padding-top:4rem;

                @media (max-width: 413px){
                    padding-top: 5rem;
                }
            }
        }
    }

    @media (max-width: 480px){
        &:before{
            height:32rem;
        }
    }

  }
    
}