//colors

$primary: #48abff;
$secondary: #feb903;
$third: #07283c;

//fonts
$font-primary: 'Source Sans Pro', sans-serif;
$font-secondary: 'Gilroy', sans-serif;


//header
$header-border-color: rgba($color: black, $alpha: 0.1);
$language-options-background: white;
$language-options-shadow: 0px 0px 15px 6px rgba(0,0,0,0.05);
$language-option-hover-background: rgba($color: black, $alpha: 0.05);
$header-button-color: #4a4a4a;

//header light
$header-light-border-color: rgba($color: white, $alpha: 0.3);
$language-options-light-background: #0d0d0d;
$language-options-light-shadow: 0px 0px 15px 6px rgba(0,0,0,0.2);
$language-option-light-hover-background: rgba($color: white, $alpha: 0.05);
$header-light-button-color: white;

//footer
$footer-disclaimer-background: #fff;
$footer-copyright-background: #fff;
$footer-disclaimer-color:#929292;
$footer-disclaimer-link-color: #0995dd;
$footer-disclaimer-link-hover-color: rgba($color: #0995dd, $alpha: 0.7);
$footer-copyright-color: #000;
$payment-providers-background: #fff;


//register
$register-text-color: #000;
$register-currency-color: #000;
$register-terms-color: #000;
$register-arrow-color: #000;

$register-error-color: #000;
$register-error-invalid-color: #000;
$register-error-valid-color:rgba($color: #000, $alpha: 0.5);


