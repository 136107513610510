#section4{
    padding:6rem 0 6rem;

    @include mobile{
        padding-top:1rem;
        padding-bottom: 4rem;
    }
    .button-wrapper{
        padding-top:3rem;
        display: flex;
        justify-content: center;

        @include mobile{
            padding-top:1rem;
        }
    }
    h3{
        font-weight: 700;
        font-size: 2.1rem;

        @include mobile-portrait{
            @include fluid-type(22px, 30px, 320px, 565px);
        }
    }
    h4{
        font-weight: 400;
        font-family: $font-primary;
        margin-bottom: 0.5rem;

        @include mobile-portrait{
            @include fluid-type(14px, 22px, 320px, 565px);
        }
    }
    .testimonials-wrapper{
        padding-top: 2.8rem;
        display: flex;
        justify-content: space-between;
        @extend .smaller-font;

        @include mobile{
            font-size: 1rem;
        }

        .column{
            width:calc(33.3% - 0.75rem);

            .testimonial-card{
                height: 100%;
                border-radius: 10px;
                background-color: #f4f4f4;
                padding:2.25rem;

                .testimonial-top{
                    padding-bottom:2.25rem;
                    display: flex;
                    align-items: center;

                    @include mobile{
                        padding-bottom: 1.5rem;
                    }
                }


                .testimonial-image{
                    
                    padding-right:2rem;
                    margin-right:2rem;
                    border-right:1px solid #c8c8c8;

                    img{
                        width: 2.85rem;
                        height: 2.85rem;
                    }
                }

                @include smalltablet-down{
                    padding: 1.5rem;

                    .testimonial-image{
                        padding-right:1rem;
                        margin-right:1rem;
                    }
                }
            }
        }
        @include mobile{
            flex-flow: column;

            .column{
                width: 100%;
                margin-bottom: 3rem;
            }

            
        }
        
    }
}


@include mobile{
    #section4{
        .button{
            background: $secondary;
            box-shadow: none;
            color: #2d272f;
            opacity: 1;
            top:0;

            &:hover{
                opacity: 0.8;
            }
        }
    }
}